import { baseUrl } from "config/axios"
import moment from "moment"

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// *Password Regex
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_~=@#$>.<,[{}()!%|'"`:;+*?&])[A-Za-z\d_~=@#$>.<,[{}()!%|'"`:;+*?&]{8,}$/

// *For Name Regex
export const nameRegex = /^[a-zA-Z\s]+$/

// *For get file size into Bytes, KB and MB
export const getFileSize = (size) => {
  let fileSize
  if (size < 1024) {
    fileSize = `${size} Bytes`
  } else if (size < 1024 * 1024) {
    const fileSizeInKBs = size / 1024;
    fileSize = `${fileSizeInKBs.toFixed(2)} KB`
  } else {
    const fileSizeInMBs = size / (1024 * 1024);
    fileSize = `${fileSizeInMBs.toFixed(2)} MB`
  }
  return fileSize
}

// *For get formatted date
export const getFormattedDate = (date) => {
  if (!date) return
  const newDate = moment(date).format('DD/MM/YYYY')
  return newDate
}

// *For get formatted date
export const getYearMonthDateFormate = (date) => {
  if (!date) return
  const newDate = moment(date).format('YYYY-MM-DD')
  return newDate
}

// *For Compare Objects
export const compareObjects = (obj1, obj2) => {
  const changes = [];
  let object1 = { ...obj1 }
  let object2 = { ...obj2 }
  for (const key in object1) {
    if (object1.hasOwnProperty(key)) {
      // *Check the key value is date or string
      object1[key] = key.includes('date') ? getFormattedDate(object1[key]) : object1[key]
      object2[key] = key.includes('date') ? getFormattedDate(object2[key]) : object2[key]
      if (!object2.hasOwnProperty(key)) {
        // *Property exists in object1 but not in object2
        changes.push(`'${key}'`);
      } else if (object1[key] !== object2[key]) {
        // *Property exists in both objects, but values differ
        let obj = {
          key: key,
          prevValue: object1[key],
          updateValue: object2[key]
        }
        changes.push(obj);
      }
    }
  }

  return changes;
}

// *For Debounce
let debounceTimer;
export const Debounce = (func, delay = 1500) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(func, delay);
};

// *For Download File
export const DownloadFile = (data) => {
  const imageSrc = data?.file.replace(/\/media\//, '')
  let fileName = imageSrc.split('_')
  fileName.shift()
  fileName.join('')
  const url = baseUrl + `/download-media?name=${fileName}&path=${imageSrc}`
  let link = document.createElement('a')
  link.href = url
  link.download = data?.name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}