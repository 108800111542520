import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import Colors from "assets/Style/Colors";

export const PrimaryButton = (props) => {

  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        textTransform: 'capitalize',
        boxShadow: 'none',
        minWidth: '150px',
        py: 1.2,
        fontSize: 14,
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const PaginationButton = (props) => {

  return (
    <Button
      {...props}
      variant="contained"
      sx={{
        py: 0.5,
        mx: 0.5,
        textTransform: 'capitalize',
        bgcolor: Colors.white,
        color: Colors.black,
        '&:hover': { bgcolor: Colors.white, color: Colors.black },
      }}
    >
      {props.children}
    </Button>
  );
}