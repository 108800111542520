import React, { Fragment, useState } from 'react';
import { Box, CardMedia, Container, Typography, Grid, Divider, IconButton, FormControl, TextField, InputAdornment, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { EmailOutlined, FacebookOutlined, HeadsetMicOutlined, Instagram, KeyboardDoubleArrowRight, LocationOnOutlined, SendOutlined, Twitter, YouTube } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import TopBar from './shared/TopBar';
import Colors from 'assets/Style/Colors';
import { FontFamily, Images } from 'assets';
import { emailRegex } from 'utils/index';
import { ErrorToaster } from 'components/Toaster';

const useStyles = makeStyles({
  mailBox: {
    position: 'absolute',
    top: '50%',
    right: '-35px',
    transform: 'translateY(-50%) skewX(-17deg)',
    height: '58px',
    width: '60px',
    fontSize: '24px',
    color: Colors.white,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: Colors.primary,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
    '&:hover .MuiSvgIcon-root, &:hover .MuiTypography-root': {
      color: Colors.primary,
      transition: '0.3s ease-in-out',
    }
  },
  copyRight: {
    fontFamily: `${FontFamily.NunitoRegular} !important`,
    color: Colors.primary,
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
    '&:hover': {
      color: Colors.white
    }
  },
});

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.white + '2b', // Change the border color here
    },
    '&:hover fieldset': {
      borderColor: Colors.white + '2b', // Change the border color on hover here
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary, // Change the border color when focused here
    },
    '& input': {
      color: Colors.white, // Change the text color here
      fontSize: '13px',
      fontWeight: 300,
      fontFamily: FontFamily.NunitoRegular
    },
  },
});

function Footer() {

  const classes = useStyles();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  const iconBtn = {
    backgroundColor: '#313c44',
    width: '40px',
    height: '40px',
    transition: '0.3s ease-in-out',
  }

  const quickLinks = [
    { label: 'Home', path: '#' },
    { label: 'Services', path: '#' },
    { label: 'About Us', path: '#' },
    { label: 'Contact Us', path: '#' },
    { label: 'Careers', path: '#' },
  ]
  const contactUs = [
    { label: 'Facebook', path: '#' },
    { label: 'Twitter', path: '#' },
    { label: 'Instagram', path: '#' },
    { label: 'Yotube', path: '#' },
  ]

  // *For NewsLetter
  const newsLetter = async (formData) => {
    setLoading(true)
    try {
      console.log(formData)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    }
  }

  return (
    <Fragment>

      {/* ========== Footer TopBar ========== */}
      <TopBar />

      {/* ========== Footer ========== */}
      <CardMedia image={Images.footerBg} sx={{ bgcolor: Colors.secondary, pt: '70px', pb: '80px', borderBottom: `1px solid ${Colors.white + '1f'}` }}>
        <Container>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={12} sm={6} md={3}>
              <Link to="/">
                <Box
                  component="img"
                  src={Images.logo}
                  alt='Galaxy World Wide Shipping'
                  height={150}
                />
              </Link>
              <Box sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOnOutlined sx={{ fontSize: '28px', mr: 1.5, color: Colors.primary }} />
                  <Typography variant="body1" sx={{ fontFamily: FontFamily.NunitoRegular, color: Colors.grey }}>
                    4821 Ridge Top Cir, Anchorage Street, Alaska 99508, USA.
                  </Typography>
                </Box>
                <Divider flexItem sx={{ borderBottom: `1px dashed #374148`, my: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <HeadsetMicOutlined sx={{ fontSize: '28px', mr: 1.5, color: Colors.primary }} />
                  <Typography component={"a"} href={'tel:97165102000'} variant="body1" sx={{ fontFamily: FontFamily.NunitoRegular, color: Colors.grey, transition: '0.3s ease-in-out', '&:hover': { color: Colors.primary } }}>
                    (+971) 6 510 2000
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ border: `1px dashed #374148`, p: 1, position: 'relative', width: { md: '100%', xs: '90%' } }}>
                <Box sx={{ bgcolor: '#1b262e', p: '18px 25px 18px 20px' }}>
                  <Typography variant="subtitle1" sx={{ color: Colors.white }}>
                    Ask Any Question?
                  </Typography>
                  <Typography variant="body1" component={"a"} href={'mailto:enquiry@gwwshipping.com'} sx={{ fontFamily: FontFamily.NunitoRegular, color: Colors.grey, transition: '0.3s ease-in-out', '&:hover': { color: Colors.primary } }}>
                    enquiry@gwwshipping.com
                  </Typography>
                </Box>
                <Box className={classes.mailBox}>
                  <EmailOutlined sx={{ fontSize: '28px' }} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ pl: { lg: '60px', md: '0', sm: '0', xs: '0' }, mt: 3 }}>
                <Typography variant="h5" sx={{ color: Colors.white }}>
                  Quick Links
                </Typography>
                <Box className={'heading-divider'} sx={{ mt: 2.5, mb: 3 }} />
                <Box component={"ul"} sx={{ p: 0 }}>
                  {quickLinks.map((item, index) => (
                    <Box key={index} component={"li"} sx={{ listStyle: 'none', pb: 3 }}>
                      <Box component={Link} to={item.path} className={classes.list}>
                        <KeyboardDoubleArrowRight sx={{ color: Colors.grey }} />
                        <Typography variant="body1" sx={{ color: Colors.grey, fontFamily: FontFamily.NunitoRegular }}>
                          {item.label}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ mt: 3 }}>
                <Typography variant="h5" sx={{ color: Colors.white }}>
                  Connect with Us
                </Typography>
                <Box className={'heading-divider'} sx={{ mt: 2.5, mb: 3 }} />
                <Box component={"ul"} sx={{ p: 0 }}>
                  {contactUs.map((item, index) => (
                    <Box key={index} component={"li"} sx={{ listStyle: 'none', pb: 3 }}>
                      <Box component={Link} to={item.path} className={classes.list}>
                        <KeyboardDoubleArrowRight sx={{ color: Colors.grey }} />
                        <Typography variant="body1" sx={{ color: Colors.grey, fontFamily: FontFamily.NunitoRegular }}>
                          {item.label}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ mt: 3 }}>
                <Typography variant="h5" sx={{ color: Colors.white }}>
                  Newsletter
                </Typography>
                <Box className={'heading-divider'} sx={{ mt: 2.5, mb: 3 }} />
                <Typography variant="body1" sx={{ color: Colors.grey, fontFamily: FontFamily.NunitoRegular, lineHeight: '32px' }}>
                  Get the latest news, tips and latest messages & special offers.
                </Typography>
                <Box component="form" onSubmit={handleSubmit(newsLetter)} sx={{ my: 1.5 }}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1.3, '.MuiFormHelperText-root': { ml: 0 } }} >
                    <CustomTextField
                      type={'email'}
                      placeholder={'Email Address'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type='submit' edge="end" >
                              {loading ? (
                                <CircularProgress size={20} sx={{ color: Colors.white }} />
                              ) : (
                                <SendOutlined color="primary" sx={{ transform: 'rotate(-38deg)' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.email?.message}
                      {...register("email", {
                        required: 'Please enter a your email address.',
                        pattern: {
                          value: emailRegex,
                          message: 'Please enter a valid email address.',
                        }
                      })}
                    />
                    {errors?.email?.message && (
                      <Typography sx={{ color: Colors.danger, fontSize: 12, textAlign: 'left' }}>{errors?.email?.message}</Typography>
                    )}
                  </FormControl>
                </Box>
                <Typography variant="subtitle1" sx={{ color: Colors.white }}>
                  Follow Us:
                </Typography>
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', my: 1 }}>
                  <Typography component={'a'} href={'https://www.facebook.com'} target={'_blank'}>
                    <IconButton sx={{ ...iconBtn, '&:hover': { bgcolor: Colors.facebook } }}>
                      <FacebookOutlined sx={{ fontSize: '18px', color: Colors.white }} />
                    </IconButton>
                  </Typography>
                  <Typography component={'a'} href={'https://www.twitter.com'} target={'_blank'}>
                    <IconButton sx={{ ...iconBtn, '&:hover': { bgcolor: Colors.twitter } }}>
                      <Twitter sx={{ fontSize: '18px', color: Colors.white }} />
                    </IconButton>
                  </Typography>
                  <Typography component={'a'} href={'https://www.instagram.com'} target={'_blank'}>
                    <IconButton sx={{ ...iconBtn, '&:hover': { bgcolor: Colors.instagram } }}>
                      <Instagram sx={{ fontSize: '18px', color: Colors.white }} />
                    </IconButton>
                  </Typography>
                  <Typography component={'a'} href={'https://www.youtube.com'} target={'_blank'}>
                    <IconButton sx={{ ...iconBtn, '&:hover': { bgcolor: Colors.youtube } }}>
                      <YouTube sx={{ fontSize: '18px', color: Colors.white }} />
                    </IconButton>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ mt: 3 }}>
                <Typography variant="h5" sx={{ color: Colors.white }}>
                  SCAN NOW! TO FIND US
                </Typography>
                <Box className={'heading-divider'} sx={{ mt: 2.5, mb: 3 }} />
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginY: '12px'
                  }}
                >
                  <Box component={'img'}
                    src={Images.qrCode}
                    sx={{
                      width: '150px',
                      height: '150px'
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant='subtitle1' sx={{ color: Colors.white }}>
                    Timings:
                  </Typography>
                  <Typography variant='caption' sx={{ color: Colors.white }}>
                    Monday – Thursday: 8:30AM – 01:30 PM | 4:00 PM – 9:00 PM
                  </Typography>
                  <Typography variant='caption' sx={{ color: Colors.white }}>
                    Friday: 8:30AM – 12:00 PM | 4:00 PM – 9:00 PM
                  </Typography>
                  <Typography variant='caption' sx={{ color: Colors.white }}>
                    Saturday: 8:00AM – 4:00 PM
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </CardMedia>

      {/* ========== Footer BottomBar ========== */}
      <Box sx={{ bgcolor: Colors.secondary, p: '25px 8px', textAlign: 'center' }}>
        <Typography variant="body1" sx={{ color: Colors.grey, fontFamily: FontFamily.NunitoRegular }}>
          © Copyright {moment().format('YYYY')}. All rights reserved.
          <Typography component={'a'} variant="body1" className={classes.copyRight}>
            &nbsp;Galaxy World Wide Shipping
          </Typography>
          . Designed by
          <Typography variant="body1" component={'a'} href='https://mangotechsolutions.com/' target={'_blank'} className={classes.copyRight}>
            &nbsp;MangoTechSolutions
          </Typography>
        </Typography>
      </Box>

    </Fragment>
  );
}

export default Footer;