import { CustomerIcon, HomeIcon, IdCardIcon, VehicleIcon, FinanceIcon } from "assets"

const Navigation = [
  {
    label: 'dashboard',
    icon: <HomeIcon />,
    path: '/dashboard',
    roleId: ""
  },
  {
    label: 'Customers',
    icon: <CustomerIcon />,
    roleId: 1,
    childPath: ["/create-customer", "/customer-queue"],
    children: [
      {
        label: "Create Customer",
        path: "/create-customer",
      },
      {
        label: "Customer Queue",
        path: "/customer-queue",
      },
    ]
  },
  {
    label: 'Buyer ID',
    icon: <IdCardIcon />,
    roleId: 1,
    childPath: ["/create-buyer-id", "/buyer-id-list", "/buyer-id-history-log"],
    children: [
      {
        label: "Add Buyer ID",
        path: "/create-buyer-id",
      },
      {
        label: "Buyer ID List",
        path: "/buyer-id-list",
      },
      {
        label: "Buyer ID History Log",
        path: "/buyer-id-history-log",
      },
    ]
  },
  {
    label: 'Vehicle',
    icon: <VehicleIcon />,
    roleId: 1,
    childPath: ["/create-vehicle-booking", "/vehicle-booking-list", "/approval-list"],
    children: [
      {
        label: "Add Vehicle Booking",
        path: "/create-vehicle-booking",
      },
      {
        label: "Vehicle Booking List",
        path: "/vehicle-booking-list",
      },
      {
        label: "Approval List",
        path: "/approval-list",
      },
    ]
  },
  {
    label: 'Finance',
    icon: <FinanceIcon />,
    roleId: 1,
    childPath: ["/exchange-rate", "/exchange-rate-logs"],
    children: [
      {
        label: "Exc Rate Setup Page",
        path: "/exchange-rate",
      },
      {
        label: "Exc Rate Logs",
        path: "/exchange-rate-logs",
      },
    ]
  },
  // {
  //   label: 'Auction Houses',
  //   icon: <HomeIcon />,
  //   roleId: 1,
  //   childPath: ["/create-auction-house", "/auction-house-list"],
  //   children: [
  //     {
  //       label: "Add Auction House",
  //       path: "/create-auction-house",
  //     },
  //     {
  //       label: "Auction House List",
  //       path: "/auction-house-list",
  //     },
  //   ]
  // },
]

export default Navigation