import React, { Fragment, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from '@mui/material';
import { LocalPhone } from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import Colors from 'assets/Style/Colors';
import TopBar from './shared/TopBar';
import { Images } from 'assets';
import { PrimaryButton } from 'components/Buttons';
import { useAuth } from 'context/UseContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
});

function Header() {

  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const textColor = { color: pathname === '/' ? Colors.white : Colors.secondary }

  const nav1 = [
    { title: 'Home', path: '/' },
    { title: 'Services', path: '/services' },
    { title: 'About Us', path: '/about' },
    { title: 'Contact Us', path: '/contact' },
    { title: 'Careers', path: '/careers' },
  ]

  const [drawer, setDrawer] = useState({ right: false });

  const anchor = ["right"]

  const toggleDrawers = (anchor, open) => (event) => {
    setDrawer({ ...drawer, [anchor]: open })
    // setDrawer(!drawer);
    // setSearchDrawer(!searchDrawer)
  }

  return (
    <Box className={pathname === '/' && classes.root} sx={{ width: 1, zIndex: 10 }}>

      {/* ========== Header Top Bar ========== */}
      <TopBar />

      <Box
        sx={{
          bgcolor: Colors.white + '26',
          boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
          display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Link to={'/'}>
              <Box
                component="img"
                src={Images.logo}
                alt='Galaxy World Wide Shipping'
                height={80}
              />
            </Link>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3, gap: 4 }}>
              {nav1.map((item, index) => (
                <Link key={index} to={item.path}>
                  <Typography variant="body2" sx={{ ...textColor, fontWeight: 700, ':hover': { color: Colors.primary } }}>
                    {item.title}
                  </Typography>
                </Link>
              ))}
              <Typography component={"a"} href={'tel:97165102000'} variant="body2"
                sx={{ ...textColor, fontWeight: 700, borderBottom: '1px solid', '&:hover': { borderColor: Colors.primary, '.MuiSvgIcon-root, .MuiTypography-root': { color: Colors.primary } } }}
              >
                <LocalPhone sx={{ ...textColor, verticalAlign: 'bottom', mr: 0.8 }} />
                <Typography component="span" variant="body2" sx={{ fontWeight: 700 }}>
                  (+971) 6 510 2000
                </Typography>
              </Typography>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                {user?.token ? (
                  <PrimaryButton
                    fullWidth
                    title={'Go To Dashboard'}
                    color={'primary'}
                    onClick={() => navigate('/dashboard')}
                  />
                ) : (
                  <Fragment>
                    <PrimaryButton
                      fullWidth
                      variant={'outlined'}
                      title={'Login'}
                      color={'primary'}
                      onClick={() => navigate('/login')}
                    />
                    <PrimaryButton
                      fullWidth
                      title={'Register'}
                      color={'primary'}
                      onClick={() => navigate('/register')}
                    />
                  </Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 11,
          backgroundColor: Colors.white,
          display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none', justifyContent: 'space-between', }
        }}
      >
        <Box
          component="img"
          src={Images.logo}
          alt='Galaxy World Wide Shipping'
          height={80}
        />
        {anchor.map((anchor, index) => (
          <Fragment key={index}>
            <Button onClick={toggleDrawers(anchor, true)}>
              {index === 0 && <MenuIcon />}
            </Button>
            <Drawer
              anchor={anchor}
              open={drawer[anchor]}
              onClose={toggleDrawers(anchor, false)}
              PaperProps={{
                style: {
                  width: '100%'
                }
              }}
            >
              {anchor === 'right' &&
                <List>
                  <ListItem>
                    <ListItemButton
                      onClick={toggleDrawers(anchor, false)}
                      sx={{ justifyContent: 'flex-end' }}
                    >
                      <CloseIcon />
                    </ListItemButton>
                  </ListItem>
                  <ListItem sx={{ justifyContent: 'center' }}>
                    <Box
                      component="img"
                      src={Images.logo}
                      alt='Galaxy World Wide Shipping'
                      height={100}
                    />
                  </ListItem>
                  {nav1.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemButton>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              }
            </Drawer>
          </Fragment>
        ))}
      </Box>
    </Box>
  );
}

export default Header;