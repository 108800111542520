import React, { lazy } from "react";

const Home = lazy(() => import('pages/Website/Home'));
const About = lazy(() => import('pages/Website/About'));
const Contact = lazy(() => import('pages/Website/Contact'));
const Service = lazy(() => import('pages/Website/Services'));
const Career = lazy(() => import('pages/Website/Career'));


const PublicRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/about",
    component: <About />,
  },
  {
    path: "/contact",
    component: <Contact />,
  },
  {
    path: "/services",
    component: <Service />,
  },
  {
    path: "/careers",
    component: <Career />,
  },
]

export default PublicRoutes