import React, { Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';
import { CircleLoading } from 'components/Loaders';
import Header from './Header';
import Sidebar from './Sidebar';

function DashboardLayout() {

  return (
    <Box sx={{ bgcolor: '#FCFCFC' }}>

      {/* ========== Header ========== */}
      <Header />

      <Box component="main" sx={{ display: 'flex' }}>

        {/* ========== Sidebar ========== */}
        <Box sx={{ height: `calc(100vh - 77px)`, overflowY: 'auto', borderRight: `1px solid #EAEAEA`, width: '280px', px: 2, py: 2 }}>
          <Sidebar />
        </Box>

        <Box sx={{ height: `calc(100vh - 77px)`, width: 1, position: 'relative', overflowY: 'auto' }}>
          <Suspense fallback={<CircleLoading />}>
            <Outlet />
          </Suspense>
        </Box>

      </Box>

    </Box>
  )
}

export default DashboardLayout