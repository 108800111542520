import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { useAuth } from 'context/UseContext';
import Colors from 'assets/Style/Colors';
import { ToasterContainer } from 'components/Toaster';
import WebsiteLayout from 'layouts/Website';
import DashboardLayout from 'layouts/Dashboard';
import PublicRoutes from 'routes/PublicRoutes';
import AuthRoutes from 'routes/AuthRoutes';
import DashboardRoutes from 'routes/DashboardRoutes';
import AdminRoutes from 'routes/AdminRoutes';
import UserRoutes from 'routes/UserRoutes';
import ScrollToTop from 'hooks/ScrollToTop';

function App() {

  const { user } = useAuth();
  let theme = createTheme();
  theme = createTheme({
    palette: {
      primary: {
        main: Colors.primary,
        contrastText: Colors.white,
      },
      secondary: {
        main: Colors.secondary,
        contrastText: Colors.white,
      },
    },
    typography: {
      fontFamily: 'DM Sans',
      h1: {
        fontSize: 65,
        fontWeight: 900,
        color: Colors.white
      },
      h2: {
        fontSize: 46,
        fontWeight: 700,
        color: Colors.textSecondary,
        [theme.breakpoints.down('md')]: {
          fontSize: '30px',
        }
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
          // fontSize: '12px',
        },
      },
      h4: {
        fontSize: 28,
        fontWeight: 700,
      },
      h5: {
        fontSize: 22,
        fontWeight: 700,
      },
      h6: {
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 600
      },
      subtitle2: {
        fontSize: 17,
        fontWeight: 400
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        }
      },
      body2: {
        fontSize: 14,
        fontWeight: 400
      },
      caption: {
        fontSize: 12,
        fontWeight: 300
      },
      overline: {
        fontSize: 10,
        fontWeight: 300
      },
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>

        {/* ========== Toaster ========== */}
        <ToasterContainer />

        <Routes>

          <Route element={<WebsiteLayout />} >
            {PublicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.token ? <Navigate to="/dashboard" /> : <WebsiteLayout />} >
            {AuthRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.token ? <DashboardLayout /> : <Navigate to="/login" />} >
            {DashboardRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.role_id === 1 ? <DashboardLayout /> : <Navigate to="/dashboard" />} >
            {AdminRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

          <Route element={user?.role_id !== 1 ? <DashboardLayout /> : <Navigate to="/dashboard" />} >
            {UserRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

        {/* ========== Scroll To Top ========== */}
        <ScrollToTop />

      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
