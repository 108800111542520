import React, { Suspense } from 'react';
import { Box } from "@mui/material";
import { Outlet } from 'react-router-dom';
import { PreLoading } from 'components/Loaders';
import Header from './Header';
import Footer from './Footer';

const LazyLoading = () => {
  return (
    <Box sx={{ display: 'block', position: 'relative', justifyContent: 'center', alignItems: 'center', minHeight: 380, height: '100vh' }}>
      <PreLoading />
    </Box>
  )
}

function WebsiteLayout() {

  return (
    <Suspense fallback={<LazyLoading />}>

      {/* ========== Header ========== */}
      <Header />

      {/* ========== Main ========== */}
      <Box component="main" sx={{ overflow: 'hidden' }}>
        <Outlet />
      </Box>

      {/* ========== Footer ========== */}
      <Footer />

      {/* ========== Scroll Top Button ========== */}
      {/* <ScrollTop /> */}

    </Suspense>
  )
}

export default WebsiteLayout