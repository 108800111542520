import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { PowerSettingsNew } from '@mui/icons-material';
import { useAuth } from 'context/UseContext';
import Colors from 'assets/Style/Colors';
import { FontFamily, GalaxyUsedCarLogo, IdCardIcon, Images } from 'assets';
import { Link } from 'react-router-dom';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';

function Header() {

  const { user, userLogout } = useAuth();

  // *For Dialog Box
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: Colors.white, px: 3, py: 1, boxShadow: '0px 4px 8px 0px #9B9B9B1A' }}>

      {/* ========== Confirmation Dialog ========== */}
      <ConfirmationDialog open={confirmationDialog} onClose={() => setConfirmationDialog(false)} message={'Are you sure you want to logout?'} action={() => { setConfirmationDialog(false); userLogout() }} />

      <Link to={'/'} >
        {/* <GalaxyUsedCarLogo /> */}
        <Box component={'img'} src={Images.usedCarLogo} sx={{ height: '50px' }} />
      </Link>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <IdCardIcon />
        <Typography variant="body2" sx={{ color: Colors.charcoalGrey, fontFamily: FontFamily.NunitoRegular }}>
          {user?.role_id === 1 ? 'Admin ID : ' : 'Customer ID : '}
        </Typography>
        <Typography variant="body2" sx={{ color: Colors.charcoalGrey, fontWeight: 700 }}>
          {user?.ref_id}
        </Typography>
        <IconButton onClick={() => setConfirmationDialog(true)}>
          <PowerSettingsNew sx={{ color: Colors.charcoalGrey, }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Header;