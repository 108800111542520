const Colors = {
    primary: '#38CB89',
    secondary: '#0f1b24',
    textSecondary: '#14212a',
    cloudyGrey: '#666666',
    charcoalGrey: '#323B4B',
    Martini: '#A3A3A3',
    grey: '#b3b3b3',
    greyCloud: '#B2B5BA',
    cloud: '#c2c2c2',
    iron: '#d9d9d9',
    lightGrey: '#797979',
    greyShade: '#959595',
    smokeyGrey: '#747474',
    dawnPink: '#F8E4E4',
    white: '#ffffff',
    whiteSmoke: '#F3F3F3',
    milkWhite: '#FAFBFC',
    flashWhite: '#EFF0F6',
    lightPink: "#FEF8F8",
    mercury: '#DFE5EB',
    black: '#222222',
    smokyBlack: '#0707074d',
    blackShade: "#0f1b24de",
    bluishCyan: '#27ADDF',
    danger: '#d32f2f',
    success: '#79AA5A',
    facebook: '#3b5999',
    twitter: '#55acee',
    instagram: '#e4405f',
    youtube: '#cd201f',
};

export default Colors;