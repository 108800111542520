import React, { Fragment, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Navigation from './Navigation';
import Colors from 'assets/Style/Colors';
import { FontFamily } from 'assets';
import { useAuth } from 'context/UseContext';

function Sidebar() {

  const { pathname } = useLocation();
  const { user } = useAuth();

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  // *For Sub Child Active
  const handleSubChildActive = (item) => {
    return (item.childPath?.indexOf(pathname) !== -1 && item.childPath?.indexOf(pathname))
  }

  return (
    <List>
      {Navigation.map((item, index) =>
        item.roleId === user?.role_id || item.roleId === "" ? (
          <Fragment key={index}>
            <ListItemButton key={index}
              component={item.path ? Link : 'div'}
              to={item.path ?? ''}
              aria-label={item?.label}
              onClick={() => { item.childPath && handleCollapse(item.label) }}
              sx={{
                borderRadius: 2,
                px: 1,
                py: 0,
                mt: 1.5,
                bgcolor: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.primary : "",
                boxShadow: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? "0px 4px 5px 0px #B8B8B840" : "",
                '&:hover': {
                  bgcolor: Colors.primary,
                  ".MuiTypography-root": {
                    color: Colors.white,
                  },
                  ".MuiListItemIcon-root": {
                    bgcolor: 'transparent',
                  },
                  "svg": {
                    "path": {
                      fill: Colors.white
                    },
                  },
                }
              }}
            >
              <ListItemIcon
                sx={{
                  bgcolor: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? "transparent" : Colors.white,
                  boxShadow: '0px 4px 5px 0px #B8B8B840',
                  minWidth: 'auto',
                  mr: 1,
                  borderRadius: '6px',
                  p: '10px',
                  'svg': {
                    height: '20px',
                    width: '20px',
                    "path": {
                      fill: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.white : "",
                    },
                  }
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2"
                    sx={{
                      textTransform: 'capitalize',
                      fontFamily: FontFamily.NunitoRegular,
                      color: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.white : Colors.charcoalGrey
                    }}>
                    {item.label}
                  </Typography>
                }
              />
              {item?.children &&
                (expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 ?
                  <ExpandLess size="small" sx={{ color: item.path === pathname || handleSubChildActive(item) || handleSubChildActive(item) === 0 ? Colors.white : Colors.charcoalGrey }} />
                  :
                  <ExpandMore size="small" sx={{ color: Colors.charcoalGrey }} />
                )
              }
            </ListItemButton>
            {item?.children &&
              <Collapse in={expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 ? true : false} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((child, i) => (
                    <ListItemButton key={i}
                      component={child.path ? Link : 'div'}
                      to={child.path ?? ''}
                      aria-label={child?.label}
                      onClick={() => { child.path && handleCollapse(child.label) }}
                      sx={{
                        borderRadius: 2,
                        px: 1,
                        py: 0.5,
                        mt: 0.5,
                        ml: '48px',
                        '&:hover': {
                          ".MuiTypography-root": {
                            color: Colors.primary,
                          },
                        }
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="body2"
                            sx={{
                              textTransform: 'capitalize',
                              fontFamily: FontFamily.NunitoRegular,
                              color: child.path === pathname || handleSubChildActive(child) || handleSubChildActive(child) === 0 ? Colors.primary : Colors.charcoalGrey
                            }}>
                            {child.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            }
          </Fragment>
        ) : (
          ""
        ))}
    </List>
  );
}

export default Sidebar;