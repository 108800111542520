import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Divider, Grid, SvgIcon, Typography } from '@mui/material';
import { AccessTimeOutlined, EmailOutlined, FacebookRounded, Instagram, RoomOutlined, Twitter, WhatsApp } from "@mui/icons-material";
import Colors from 'assets/Style/Colors';

function TopBar() {

  const nav1 = [
    { title: 'About Us', path: '#' },
    { title: 'Faq', path: '#' },
    { title: 'Blog', path: '#' },
  ]

  const socialNav = [
    { icon: FacebookRounded, path: '#' },
    { icon: Twitter, path: '#' },
    { icon: Instagram, path: '#' },
    { icon: WhatsApp, path: '#' },
  ]

  return (
    <Box sx={{ bgcolor: '#f1fdf8', display: { xs: 'none', lg: 'block' } }}>
      <Container maxWidth="xl">
        <Grid container spacing={0} alignItems={"center"} justifyContent={'space-between'}>
          <Grid item md={6}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailOutlined fontSize="medium" sx={{ verticalAlign: 'bottom', color: Colors.primary, mr: 0.5 }} />
                <Typography component={'a'} href={'mailto:enquiry@gwwshipping.com'} variant="caption" sx={{ color: Colors.textSecondary, '&:hover': { color: Colors.primary } }}>
                  enquiry@gwwshipping.com
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <AccessTimeOutlined fontSize="medium" sx={{ verticalAlign: 'bottom', color: Colors.primary, mr: 0.5 }} />
                <Box>
                  <Typography variant="caption" sx={{ color: Colors.textSecondary }}>
                    Monday – Thursday: 8:30AM – 01:30 PM | 4:00 PM – 9:00 PM
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', mr: 3 }}>
                {nav1.map((item, index) => (
                  <Fragment key={index}>
                    <Link to={item.path}>
                      <Typography variant="caption" sx={{ color: Colors.textSecondary, ':hover': { color: Colors.primary } }}>
                        {item.title}
                      </Typography>
                    </Link>
                    {nav1.length !== index + 1 &&
                      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                    }
                  </Fragment>
                ))}
              </Box>
              {socialNav.map((item, index) => (
                <Link to={item.path} key={index}>
                  <Box sx={{ borderLeft: `1px dashed ${Colors.primary}`, borderRadius: '50%', p: 2, ':hover': { '.MuiSvgIcon-root': { color: Colors.primary, transition: '0.3s ease-in-out' } } }}>
                    <SvgIcon
                      component={item.icon}
                      fontSize="small"
                      inheritViewBox
                      sx={{ verticalAlign: 'bottom', mr: 0.5 }}
                    />
                  </Box>
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default TopBar;