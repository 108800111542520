import React, { lazy } from "react";

const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const RequestBuyerId = lazy(() => import('pages/Dashboard/BuyerId/RequestBuyerId'));

const DashboardRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/request-buyer-id",
    component: <RequestBuyerId />,
  },
]

export default DashboardRoutes
