import React, { lazy } from "react";

const CreateCustomer = lazy(() => import('pages/Dashboard/Customers/CreateCustomer'));
const CustomerQueue = lazy(() => import('pages/Dashboard/Customers/CustomerQueue'));
const CustomerDetail = lazy(() => import('pages/Dashboard/Customers/CustomerDetail'));
const BuyerIdDetail = lazy(() => import('pages/Dashboard/Customers/BuyerIdDetail'));
const Message = lazy(() => import('pages/Dashboard/Customers/Message'));
const CreateBuyerId = lazy(() => import('pages/Dashboard/BuyerId/CreateBuyerId'));
const UpdateBuyerId = lazy(() => import('pages/Dashboard/BuyerId/UpdateBuyerId'));
const BuyerIdList = lazy(() => import('pages/Dashboard/BuyerId/BuyerIdList'));
const BuyerIdHistoryLog = lazy(() => import('pages/Dashboard/BuyerId/BuyerIdHistoryLog'));
const RequestBuyerId = lazy(() => import('pages/Dashboard/BuyerId/RequestBuyerId'));
const CreateAuctionHouse = lazy(() => import('pages/Dashboard/AuctionHouse/CreateAuctionHouse'));
const AuctionHouseList = lazy(() => import('pages/Dashboard/AuctionHouse/AuctionHouseList'));
const CreateVehicleBooking = lazy(() => import('pages/Dashboard/VehicleBooking/CreateVehicleBooking'));
const UpdateVehicleBooking = lazy(() => import('pages/Dashboard/VehicleBooking/UpdateVehicleBooking'));
const VehicleBookingList = lazy(() => import('pages/Dashboard/VehicleBooking/VehicleBookingList'));
const VehicleBookingDetail = lazy(() => import('pages/Dashboard/VehicleBooking/VehicleBookingDetail'));
const ApprovalList = lazy(() => import('pages/Dashboard/VehicleBooking/ApprovalList'));
const ExchangeRate = lazy(() => import('pages/Dashboard/Finance/ExchangeRate'));
const ExchangeRateLog = lazy(() => import('pages/Dashboard/Finance/ExchangeRateLog'));

const AdminRoutes = [
  {
    path: "/create-customer",
    component: <CreateCustomer />,
  },
  {
    path: "/customer-queue",
    component: <CustomerQueue />,
  },
  {
    path: "/customer-detail/:id",
    component: <CustomerDetail />,
  },
  {
    path: "/buyer-id-detail/:id",
    component: <BuyerIdDetail />,
  },
  {
    path: "/message/:id",
    component: <Message />,
  },
  {
    path: "/create-buyer-id",
    component: <CreateBuyerId />,
  },
  {
    path: "/update-buyer-id",
    component: <UpdateBuyerId />,
  },
  {
    path: "/buyer-id-list",
    component: <BuyerIdList />,
  },
  {
    path: "/buyer-id-history-log",
    component: <BuyerIdHistoryLog />,
  },
  {
    path: "/request-buyer-id/:id",
    component: <RequestBuyerId />,
  },
  {
    path: "/create-auction-house",
    component: <CreateAuctionHouse />,
  },
  {
    path: "/auction-house-list",
    component: <AuctionHouseList />,
  },
  {
    path: "/create-vehicle-booking",
    component: <CreateVehicleBooking />,
  },
  {
    path: "/update-vehicle-booking/:id",
    component: <UpdateVehicleBooking />,
  },
  {
    path: "/vehicle-booking-list",
    component: <VehicleBookingList />,
  },
  {
    path: "/vehicle-booking-detail/:id",
    component: <VehicleBookingDetail />,
  },
  {
    path: "/approval-list",
    component: <ApprovalList />,
  },
  {
    path: "/exchange-rate",
    component: <ExchangeRate />,
  },
  {
    path: "/exchange-rate-logs",
    component: <ExchangeRateLog />,
  },
]

export default AdminRoutes
